import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components";


const ThankYou = () => (
  <Layout>
    <Seo title="ThankYou" />
    <div id="thankYouWrapper">
      <div id="thankYouContent">
        <h3>Thank you for reaching out!</h3>
        <p>I'll try to reach back out to you as soon as possible!</p>
        <p>Until then, feel free to look at my other work <Link to="/">here</Link>.</p>
    </div>
    </div>
  </Layout>
)

export default ThankYou;
